<template>
  <section id="conditional-message">
    <KonectaFilters @onFiltersQuantityChange="onFilterChange" />

    <p class="jumps-disclaimer" v-show="!isEditing && currentDialogHasJumps">
      <vs-icon icon="error"></vs-icon>
      {{ lang.botMaker.botEditor.jumpDisclaimer[languageSelected] }}
    </p>
    <p class="jumps-disclaimer" v-show="!isEditing && currentDialogHasSurveys">
      <vs-icon icon="error"></vs-icon>
      {{ lang.botMaker.botEditor.surveyDisclaimer[languageSelected] }}
    </p>
    <p
      class="jumps-disclaimer"
      v-show="!isEditing && currentDialogHasValidation"
    >
      <vs-icon icon="error"></vs-icon>
      {{ lang.botMaker.botEditor.validationDisclaimer[languageSelected] }}
    </p>

    <div class="footer flex justify-center mt-4">
      <!-- cancel -->
      <!-- :disabled="disableAdd" -->
      <vs-button color="danger" type="flat" @click="onCancel">{{
        lang.botMaker.botEditor.cancelMessage[languageSelected]
      }}</vs-button>

      <!-- save -->
      <!-- :disabled="disableAdd" -->

      <vs-button
        v-if="!isEditing"
        class="save-button ml-4"
        @click="saveConditionalMessage"
        :disabled="disableSave"
        >{{ lang.botMaker.botEditor.addMessage[languageSelected] }}</vs-button
      >

      <!-- modify -->
      <vs-button
        v-else
        class="ml-4"
        @click="updateConditionalMessage"
        :disabled="disableUpdate"
        >{{
          lang.botMaker.botEditor.modifyMessage[languageSelected]
        }}</vs-button
      >
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import { errorHandler } from '../../../../../../services/ErrorHandler'

export default {
  name: 'ConditionalMessage',
  data() {
    return {
      disableUpdate: false
    }
  },
  components: {
    KonectaFilters: () => import('./konectaFilters/KonectaFilters.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('botMaker', [
      'botMakerMessages',
      'userMessageInEdit',
      'conditionsFilters',
      'lastAlternativeDialogSavedId',
      'conditionName',
      'secondLevelAttention',
      'numberOfSurveys',
      'currentDialogHasJumps',
      'currentDialogHasSurveys',
      'currentDialogHasValidation',
      'botMessageInEdit',
      'bot'
    ]),
    dialogId() {
      if (this.userMessageInEdit && this.userMessageInEdit.isEditing) {
        return this.userMessageInEdit.dialogId
      }
      if (this.botMessageInEdit) {
        return this.botMessageInEdit.dialogId
      }
      return null
    },
    isEditing() {
      return (
        this.userMessageInEdit &&
        this.userMessageInEdit.isEditing &&
        this.userMessageInEdit.isSaved
      )
    },
    disableSave() {
      return (
        this.conditionsFilters.types.length === 0 ||
        this.currentDialogHasJumps ||
        this.currentDialogHasSurveys
      )
    }
  },
  methods: {
    ...mapActions('botMaker', [
      'UPDATE_CONDITIONAL_MESSAGE',
      'GET_DIALOGS',
      'SAVE_CONDITIONAL_MESSAGE'
    ]),
    ...mapMutations('botMaker', [
      'UPDATE_MESSAGE',
      'RESET_CONDITIONS',
      'LAST_ALTERNATIVE_DIALOG_SAVED_ID',
      'RESET_USER_MESSAGE',
      'UPDATE_USER_MESSAGE_TYPE',
      'SET_CONDITIONS_IN_EDIT',
      'RESET_CONDITION_TAGS',
      'REMOVE_MESSAGE'
    ]),
    saveConditionalMessage() {
      if (!this.userMessageInEdit.isSaved) {
        // set generic message
        this.UPDATE_USER_MESSAGE_TYPE()
        // if (!this.userMessageInEdit.type.isValid()) {
        //   return
        // }
        let dialogSavedId =
          (this.userMessageInEdit && this.userMessageInEdit.dialogSavedId) ||
          (this.botMessageInEdit && this.botMessageInEdit.dialogSavedId)

        if (!dialogSavedId) {
          dialogSavedId = this.lastAlternativeDialogSavedId
          this.LAST_ALTERNATIVE_DIALOG_SAVED_ID()
        }

        const getDialogsId =
          this.botMakerMessages[0] && this.botMakerMessages[0].dialogSavedId
        this.$vs.loading()
        this.userMessageInEdit.filters = this.conditionsFilters

        this.SAVE_CONDITIONAL_MESSAGE(this.userMessageInEdit)
          .then(async () => {
            // TODO: used in finally
            this.RESET_USER_MESSAGE()
            this.$vs.notify({
              color: 'success',
              title: this.lang.botMaker.botEditor.addConditionalMsg.success
                .title[this.languageSelected],
              text: this.lang.botMaker.botEditor.addConditionalMsg.success.text[
                this.languageSelected
              ]
            })
          })
          .catch(async error => {
            errorHandler(error)
            this.REMOVE_MESSAGE(this.userMessageInEdit)
            await this.GET_DIALOGS(dialogSavedId || getDialogsId)
          })
          .finally(() => {
            this.RESET_USER_MESSAGE()
            this.$vs.loading.close()
          })
      }
    },
    updateConditionalMessage() {
      if (this.userMessageInEdit.isSaved) {
        const getDialogsId =
          this.botMakerMessages && this.botMakerMessages[0].dialogSavedId
        this.$vs.loading()
        this.userMessageInEdit.filters = this.conditionsFilters
        //this.userMessageInEdit.condition.execTakeover = this.secondLevelAttention;
        const { dialogSavedId } = this.userMessageInEdit
        this.UPDATE_CONDITIONAL_MESSAGE(this.userMessageInEdit)
          .then(async () => {
            // await this.GET_DIALOGS(
            //   this.userMessageInEdit.dialogSavedId || getDialogsId
            // )
            this.UPDATE_MESSAGE(this.userMessageInEdit)
            this.userMessageInEdit.isEditing = false
            this.$vs.notify({
              color: 'success',
              title: this.lang.botMaker.botEditor.editConditionalMsg.success
                .title[this.languageSelected],
              text: this.lang.botMaker.botEditor.editConditionalMsg.success
                .text[this.languageSelected]
            })
          })
          .catch(error => {
            errorHandler(error)
          })
          .finally(async () => {
            await this.GET_DIALOGS(dialogSavedId || getDialogsId)
            this.RESET_CONDITIONS()
            this.$vs.loading.close()
          })
      }
    },
    onCancel() {
      if (this.userMessageInEdit.isSaved) {
        this.SET_CONDITIONS_IN_EDIT(this.userMessageInEdit)
      } else {
        this.RESET_CONDITIONS()
      }
    },
    onFilterChange(filtersCount) {
      this.disableUpdate = filtersCount < 1
    }
  },
  mounted() {
    this.RESET_CONDITION_TAGS()
  }
}
</script>

<style lang="scss">
#conditional-message {
  display: flex;
  flex-direction: column;
  .jumps-disclaimer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    color: rgba(var(--vs-danger), 1);
    font-size: 10px;
  }
  .footer {
    display: flex;
    justify-content: flex-end;
  }
}
</style>
